import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/generalization/generalization.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/generalization',
    // name: 'generalization',
    // component: HomeView
  },
  {
    path: '/generalization',
    name: 'generalization',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  },
  {
    path: '/investmentAdvantages',
    name: 'investmentAdvantages',
    component: () => import(/* webpackChunkName: "about" */ '../views/investmentAdvantages/investmentAdvantages')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/industrialSystem',
    name: 'industrialSystem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/industrialSystem/industrialSystem.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    // 资源要素
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/resources/resources.vue')
  },
  {
    path: '/policySupport',
    name: 'policySupport',
    // 政策支持
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/policySupport/policySupport.vue')
  },
  {
    path: '/toInvest',
    name: 'toInvest',
    // 我要投资
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/toInvest/toInvest.vue')
  },
  {
    path: '/investmentGuidance',
    name: 'investmentGuidance',
    // 投资引导-高端装备
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/investmentGuidance/investmentGuidance.vue')
  },
  {
    path: '/investmentIndustry',
    name: 'investmentIndustry',
    // 投资引导-产业体系
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/investmentIndustry/investmentIndustry.vue')
  },
  {
    path: '/elementGuarantee',
    name: 'elementGuarantee',
    // 要素保障
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/elementGuarantee/elementGuarantee.vue')
  },
  {
    path: '/searchEngines',
    name: 'searchEngines',
    // 搜索
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/searchEngines/searchEngines.vue')
  },
  {
    path: '/dataServe',
    name: 'dataServe',
    // 搜索
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/dataServe/dataServe.vue')
  },

]

const router = new VueRouter({
  linkExactActiveClass: 'webActive',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
