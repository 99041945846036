<template>
  <!-- 文本详情 -->
  <div class="support_popup" v-show="popShow" @click.stop="popShowBtn">
      <img src="../assets/xxx.png" alt="" class="popup_ximg" @click.stop="popShowBtn">
      <!-- @click.stop="popShowStop" -->
      <div class="popup_content" >
        <!--  v-if="acType == 1 || acType == 2" -->
        <p class="popup_title" v-if="acType == 1">{{ popData.title }}</p>
        <p class="popup_title" v-if="acType == 2">{{ popData.name }}</p>
        <div class="d_f j_c_sb a_i_c popup_time">
          <p>{{ popData.updated_at }}</p>
          <p>{{ popData.author }}</p>
        </div>
        <div class="popup_text" v-if="acType == 1" v-html="popData.content"></div>
        <div class="popup_text" v-if="acType == 2" v-html="popData.jieshao"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'popupDetail',
  props: {
    popData:{
      type:Object
    },
    popShow:{
      type:Boolean,
      default:false
    },
    acType:{
      type:Number
    }
  },
  methods:{
    popShowBtn(){
      this.$emit("popbtn")
    }
  }
}
</script>
<style>
.popup_ximg{
  position: absolute;
  width: 48px;
  height: 48px;
  top: 20px;
  right: 140px;
  cursor: pointer;
}
.popup_text {
  height: 72%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.popup_text > p {
  color: #ffffff;
  line-height: 1.8;
}
.popup_title {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  color: #ffffff;
  padding: 24px 0;
}
.popup_time {
  font-size: 18px;
  color: #376092;
  padding-bottom: 20px;
  border-bottom: 1px solid #376092;
  margin-bottom: 10px;
}
.popup_content {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  height: 80%;
  background-color: #011742;
  padding: 30px 40px 30px;
  box-sizing: border-box;
}
.support_popup {
  position: fixed;
  width: 1536px;
  height: 718px;
  background-color: rgba(1, 30, 90, 0.8);
  top:0;
  left: 0;
  z-index: 80;
}
</style>
